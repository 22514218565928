import Axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import Chatbot from '../ChatBot/ChatBotIndex';
import './newCss.css'; // Asegúrate de crear este archivo para los estilos
import NewModuleContactForm from './NewModuleContactForm';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import ErrorBoundary from '../ErrorBoundary';
const queryString = require('query-string');

// const data = require('./exampleJson.json')
// Main Chatbot component
const NewContactForm = () => {
  const [isStartedChat, setIsStartedChat] = useState(false)
  const [isOpenOldQueries, setIsOpenOldQueries] = useState(false)
  const [values, setValues] = useState({ username: null, password: null, error: false, success: false });


  React.useEffect(() => {
    // let res = decryptResponse(localStorage.getItem('nigol'))
    const parsed = queryString.parse(window.location.search);
    // console.log(parsed)
    if (parsed.appName) {
      if (localStorage.getItem('appInfo')) {
        // setAppInfo(JSON.parse(localStorage.getItem('appInfo')))
      } else {
        // setAppInfo(parsed)
        localStorage.setItem('appInfo', JSON.stringify(parsed))
      }
    }
    const uuid = localStorage.getItem("uuid")
    console.log(uuid)
    if (uuid) {
      setValues({ id: uuid, name: values.name })
      console.log(uuid); // This is the pseudo-unique browser identifier
      // getQueriesByUser({ id: uuid })
    } else {
      const fpPromise = FingerprintJS.load();
      fpPromise.then(fp => {
        fp.get().then(result => {
          localStorage.setItem("uuid", result.visitorId)
          setValues({ id: result.visitorId, name: values.name })
          console.log(result.visitorId); // This is the pseudo-unique browser identifier
          // getQueriesByUser({ id: result.visitorId })
        });
      });
    }

  }, []);
  if (isStartedChat) {
    return (<ErrorBoundary> <Chatbot hideChat={() => { setIsStartedChat(false) }}></Chatbot></ErrorBoundary >)
  } else if (isOpenOldQueries) {
    return (<ErrorBoundary><NewModuleContactForm hideQueries={() => { setIsOpenOldQueries(false) }}></NewModuleContactForm></ErrorBoundary>)
  } else {
    if (isMobile) {
      return (
        <div className="app-container">
          <div className="text-container">
            <h1 className="welcome-title">WELCOME</h1>
            <h2 className="main-title">This Is Givvy Customer Support Panel</h2>
            <p className="subtext">
              Let us know how we can assist you. <br />
            </p>
          </div>
          <div className="robot-container">
            <img src={require('./assets/robot-image.png')} alt="Robot Assistant" />
          </div>
          <div className="button-container">
            <button onClick={() => { setIsStartedChat(true) }} className="btn start-chat">Start Chat</button>
            <button onClick={() => { setIsOpenOldQueries(true) }} className="btn old-queries">Old Queries</button>
          </div>
        </div>
      );
    } else {
      return (<div className="app-container">
        <div className='d-flex align-items-center justify-content-center'>
          <div className="text-container">
            <h1 className="welcome-title">WELCOME</h1>
            <h2 className="main-title">This Is Givvy Customer Support Panel</h2>
            <p className="subtext">
              Let us know how we can assist you. <br />
            </p>
            <div className="button-container">
              <button onClick={() => { setIsStartedChat(true) }} className="btn start-chat">Start Chat</button>
              <button onClick={() => { setIsOpenOldQueries(true) }} className="btn old-queries">Old Queries</button>
            </div>
          </div>
          <div className="robot-container">
            <img src={require('./assets/robot-image.png')} alt="Robot Assistant" />
          </div>
        </div>
      </div>)
    }
  }

}

export default NewContactForm;