import React, { useState } from "react";
import QueryDetails from "./QueryDetails";
import QueryList from "./QueryList";
import './queryList.css'
const QueriesHeader = ({ currQueries, hideQueries, userId, isLoading }) => {
  const [isSelectedQuery, setIsSelectedQuery] = useState(null)

  const selectQuery = (currQuery) => {
    setIsSelectedQuery(currQuery)
  }
  if (isLoading) {
    return (<div className="robot-container">
    <img src={require('./assets/robot-image.png')} alt="Robot Assistant" />
  </div>)
  } else {
    return (
      <div className="query-wrapper">
        <div className="query-container">
          <h2 className="welcome-text">WELCOME</h2>
          <p className="description">
            LET US KNOW ABOUT THE ISSUE YOU ARE FACING.
          </p>

          {isSelectedQuery == null ? <QueryList userId hideQueries={hideQueries} queries={currQueries} selectQuery={(item) => { selectQuery(item) }}></QueryList> : <QueryDetails selectQuery={(item) => { selectQuery(item) }} query={isSelectedQuery}></QueryDetails>}
        </div>
      </div>
    );
  }

};

export default QueriesHeader;
