/* eslint-disable jsx-a11y/anchor-is-valid */
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import { GoogleLogin } from 'react-google-login';
// nodejs library that concatenates classes
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col, Modal, ModalHeader, ModalBody
} from "reactstrap";
// core components
import axios from "axios"
import Lottie from "lottie-react";
import groovyWalkAnimation from "./../groovyWalk.json";
import {
  isMobile
} from "react-device-detect";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import QueryList from "./QueryList";
import QueriesHeader from "./QueriesHeader";
const Aws = require('aws-sdk')
const apiKey = 'AIzaSyB7WM80SpDgx4Rx3OyQkE6BV1afhQF2n48'
const googleTranslate = require("google-translate")(apiKey);
// const crypto = require('crypto')
const queryString = require('query-string');
const maxSize = 15000000



export default function NewModuleContactForm({ hideQueries }) {
  const [values, setValues] = useState({ username: null, password: null, error: false, success: false });
  const [valuesFacebookOrGoogle, setValuesFacebookOrGoogle] = useState({});
  const [login, setLogin] = useState(0)
  const [selectButton, setSelectButton] = useState('list')
  const [appNames, setAppNames] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [appSelected, setAppNameSelected] = useState('')
  const [appInfo, setAppInfo] = useState(null)
  const [externalAppName, setExternalAppName] = useState('')
  const [defaultUsername, setDefaultUsername] = useState('')
  const [topic, setTopic] = useState('')
  const [explanation, setExplanation] = useState('')
  const [username, setUsername] = useState('')
  const [selectClass, setSelectClass] = useState('')
  const [select2, setSelect2] = useState('')
  const [uploadedImages, setUploadedImages] = useState([])
  const [queries, setQueries] = useState([])
  const [selectQuery, setSelectQuery] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [isSend, setIsSend] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [submitButtonDisabled, setSubmitButtonDissabled] = useState(false)
  const [isGettedApps, setIsGettedApps] = useState(false)
  // const cookies = new Cookies()
  const [isLogging, setIsLogging] = useState(localStorage.getItem('nigol'))
  // const history = useHistory();

  // const cryptResponse = (object, key) => {
  //   const cipher = crypto.createCipher('aes-128-ecb', key || 'daumirathakerite')
  //   const text = JSON.stringify(object)
  //   var crypted = cipher.update(text, 'utf-8', 'hex')
  //   crypted += cipher.final('hex')

  //   return crypted
  // }
  // const decryptResponse = (cookies) => {
  //   const decipher = crypto.createDecipher('aes-128-ecb', 'daumirathakerite')
  //   var dec = decipher.update(cookies, 'hex', 'utf8')
  //   dec += decipher.final('utf8')
  //   const res = JSON.parse(dec)
  //   return res
  // }
  React.useEffect(() => {
    // let res = decryptResponse(localStorage.getItem('nigol'))
    const parsed = queryString.parse(window.location.search);
    // console.log(parsed)
    if (parsed.appName) {
      if (localStorage.getItem('appInfo')) {
        // setAppInfo(JSON.parse(localStorage.getItem('appInfo')))
      } else {
        // setAppInfo(parsed)
        localStorage.setItem('appInfo', JSON.stringify(parsed))
      }
    }
    const uuid = localStorage.getItem("uuid")
    console.log(uuid)
    if (uuid) {
      setValues({ id: uuid, name: values.name })
      console.log(uuid); // This is the pseudo-unique browser identifier
      getQueriesByUser({ id: uuid })
    } else {
      const fpPromise = FingerprintJS.load();
      fpPromise.then(fp => {
        fp.get().then(result => {
          localStorage.setItem("uuid", result.visitorId)
          setValues({ id: result.visitorId, name: values.name })
          console.log(result.visitorId); // This is the pseudo-unique browser identifier
          getQueriesByUser({ id: result.visitorId })
        });
      });
    }
    if (true)
      //   setValues(res)

      // window.addEventListener('popstate', (event) => {
      //   // console.log(event);
      //   setSelectButton(event.state.page)
      // });
      // localStorage.setItem('appInfo', parsed)
      // if (window.performance && window.performance.navigation.type == window.performance.navigation.TYPE_BACK_FORWARD) {
      //   setSelectButton(localStorage.getItem('customHistory'))
      // }
      // decrypt(cookies.get('nigol')).then((res) => {

      // })
      getAppNames()

  }, []);
  React.useEffect(() => {
    if (isGettedApps) {
      const parsed = queryString.parse(window.location.search);
      console.log(parsed)
      if (parsed.appName) {
        setExternalAppName(parsed.appName)
        setDefaultUsername(parsed.username)
        setValues({ id: values.id, name: parsed.username })
      }
    }
  }, [isGettedApps])

  // 
  function usernameOnChange(e) {
    setValues({ ...values, username: e.target.value })
  }
  function passwordOnChange(e) {
    setValues({ ...values, password: e.target.value })
  }
  function allowDrop(ev) {
    ev.preventDefault();
  }

  function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }
  const handleImage = (event) => {
    let files = event.target.files;
    setSelectedImages(files)
    let totalSize = Array.from(files).map(v => v.size).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    // console.log(totalSize)
    if (totalSize > maxSize) {
      alert(`Max size is 15 MB`)
      setSelectedImages([])
    }
  }

  function drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
  }
  function readFiles(value, callback) {
    var reader = new FileReader();

    reader.onload = function (e) {
      callback(e.currentTarget.result)
    }
    reader.readAsDataURL(value);
  }
  const uploadImage = async (base64Path, callback) => {
    const s3 = new Aws.S3({
      accessKeyId: process.env.AWS_ACCESS_KEY_ID || 'AKIAQ26MT3W7SI7DRUMB',
      secretAccessKey: process.env.AWS_ACCESS_KEY_SECRET || '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',
      region: 'eu-west-1'
    })

    // var fs = require('fs')
    // var data = fs.readFileSync(base64Path)
    // console.log('photo')
    let newImage = base64Path
    let name = base64Path.slice(25, 35)
    // let type = newImage.match(/^data:image\/(jpeg|png|jpg);base64/)[1]
    var data = Buffer.from(newImage.replace(/^data:image\/(jpeg|png|jpg);base64,/, ''), 'base64')
    const params = {
      Bucket: process.env.AWS_BUCKET_NAME || 'contact-bucket173531-dev',
      Key: `${name}_${Date.now().toString()}.png`,
      Body: data,
      ACL: 'public-read-write',
      ContentEncoding: '7bit',
      ContentType: `image/jpg`
    }
    s3.upload(params, (error, data) => {
      // console.log(error)
      if (!error && data) {
        callback(data.Location)
      } else {
        console.log(`error amazon upload`)
        console.log(error)
      }
    })
  }
  const uploadVideo = async (file, callback) => {
    const s3 = new Aws.S3({
      accessKeyId: process.env.AWS_ACCESS_KEY_ID || 'AKIAQ26MT3W7SI7DRUMB',
      secretAccessKey: process.env.AWS_ACCESS_KEY_SECRET || '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',
      region: 'eu-west-1'
    })

    // Files Parameters
    // console.log(file)
    const params = {
      Bucket: process.env.AWS_BUCKET_NAME || 'contact-bucket173531-dev',
      Key: file.name,
      Body: file,
      ACL: 'public-read-write',
      ContentEncoding: '7bit',
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        // console.log(
        //   "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        // );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // Fille successfully uploaded
      // console.log(JSON.stringify(data))
      console.log("File uploaded successfully.");
      callback(`https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/${file.name}`)
    });
  };
  const submitImages = async (callback) => {
    let arrImages = []
    setIsLoading(true)
    if (selectedImages.length > 0) {
      let notImages = 0
      Array.from(selectedImages).map((value, index) => {
        if (/video/.test(value.type)) {
          console.log(`video`)
          uploadVideo(value, function (url) {
            arrImages.push(url)
          })
        } else if (/image/.test(value.type)) {
          console.log(`image`)
          readFiles(value, function (base64) {
            uploadImage(base64, function (url) {
              // console.log(url)
              arrImages.push(url)
            })
          })
        } else {
          notImages++
        }


      })
      let interval = setInterval(() => {
        if ((arrImages.length + notImages) == Array.from(selectedImages).length) {
          clearInterval(interval)
          console.log(arrImages)
          setIsLoading(false)
          callback(arrImages)
        } else {
          console.log(arrImages.length, Array.from(selectedImages).length)
        }
      }, 300)
    } else {
      setIsLoading(false)
      callback(arrImages)
    }

    // setIsLoading(false)
  }
  const deleteImage = (name) => {
    //console.log(name)
    // submitImages()
    let findImage = Array.from(selectedImages).findIndex(f => f.name == name)
    //console.log(findImage)
    var fileBuffer = [];

    // append the file list to an array
    Array.prototype.push.apply(fileBuffer, selectedImages);
    fileBuffer.splice(findImage, 1)
    setSelectedImages(fileBuffer)
  }
  const getAppNames = () => {
    console.log(`appNames`)
    axios.get('https://givvy-general-payment.herokuapp.com/getAllAppsSchemaForAdminPanel').then(res => {
      let appsCurr = [{ appName: "genericQuestion", realName: 'Generic Question' }, ...res.data.appObjects1]
      setAppNames(appsCurr)
      // if (!isGettedApps) {
      setIsGettedApps(true)
      // }
    })
  }

  const renderImages = () => {
    return Array.from(selectedImages).map((value, index) => {
      let newSrc = URL.createObjectURL(value)
      return (
        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex justify-content-start">
            <div>
              <img id="image-uploaded" src={newSrc} />
            </div>
            <div className="mx-2">
              <p style={{ fontSize: '10px', padding: 0, margin: 0 }}>{value.name}</p>
              <p style={{ fontSize: '7px', padding: 0, margin: 0 }}>{value.size / 1000}KB</p>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="m-0" onClick={() => { deleteImage(value.name) }}>
              <img src="https://image.freepik.com/free-icon/delete-button_318-85849.jpg" className="close-button" alt="" />
            </div>
          </div>
        </div>
      )
    })
  }
  const renderAppNames = () => {
    console.log(appInfo)
    return appNames.map((value) => {

      return (<option>{value.realName}</option>)
    })
  }
  const getQueriesByUser = (res) => {
    //console.log(res)
    setIsLoading(true)
    axios.post('https://givvy-backend-site.herokuapp.com/getQueriesByUser', { userId: res.id }).then((res) => {
      setQueries(res.data.result)
      setIsLoading(false)
    })
  }
  const setRightAppName = () => {
    // console.log(appNames)
    let currApp = appNames.find(f => f.appName == appInfo.appName).realName
    // console.log(currApp)
    return 'krasi'
  }
  const resolveIssue = (res) => {
    // console.log(res)
    let check = window.confirm(`Are you certain that the problem has been resolved?`);
    if (check) {
      axios.post('https://givvy-backend-site.herokuapp.com/resolvedIssue', { queryId: selectQuery._id }).then((res) => {
        let check = window.confirm(`Are you sure about this issue?`);
        alert('Issue was resolved')
        // setSelectQuery(res.data.result)
        setSelectButton('list')
        getQueriesByUser(values)
      })
    }
  }
  const sendForm = () => {
    let currUsername = username
    let currAppSelected = appSelected
    // console.log(defaultUsername, externalAppName)
    if (!currUsername && defaultUsername) {
      currUsername = defaultUsername
    }
    if (!currAppSelected && externalAppName) {
      currAppSelected = externalAppName
    }
    // console.log(topic, username, explanation, appSelected)
    if (!submitButtonDisabled) {
      setSubmitButtonDissabled(true)
      if (topic && explanation && currUsername && (currAppSelected != '' && currAppSelected != 'Choose an app')) {
        submitImages(function (arrImages) {
          axios.post('https://givvy-backend-site.herokuapp.com/saveContactFormUser', {
            images: arrImages,
            userId: values.id,
            appName: appNames.find(f => f.realName == currAppSelected).appName,
            appRealName: currAppSelected,
            topic: topic,
            username: currUsername,
            explanation: explanation
          }).then((res) => {
            // console.log(res)
            if (res && res.status == 200 && res.data.result.date) {
              setSelectedImages([])
              setExplanation('')
              document.getElementsByTagName('textarea')[0].value = ''
              setIsLoading(true)
              getQueriesByUser(values)
              setTimeout(() => {
                setIsLoading(false)
                setIsSend(true)
                setSubmitButtonDissabled(false)
                localStorage.setItem('appInfo', '')
                setAppInfo('')
                setDefaultUsername('')
                setExternalAppName('')
              }, 1000)
            } else {
              alert(`You may only submit one query per day.`)
              setSubmitButtonDissabled(false)
            }
          })
        })
      } else {
        alert(`You cannot submit the form without providing a Topic, Explanation, or Application.`)
        setSubmitButtonDissabled(false)
      }
    }

  }
  const seenReply = (value) => {
    axios.post('https://givvy-backend-site.herokuapp.com/userSeenReplies', {
      queryId: value._id
    }).then((res) => {
    })
  }
  const sendReply = () => {
    if (!submitButtonDisabled) {
      setSubmitButtonDissabled(true)
      submitImages(function (arrImages) {
        axios.post('https://givvy-backend-site.herokuapp.com/replyQuery', {
          images: arrImages,
          userId: values.id,
          queryId: selectQuery._id,
          explanation: explanation,
          isByUser: true
        }).then((res) => {
          // console.log(res)
          if (res && res.status == 200) {
            selectQuery.replies.unshift(res.data.result)
            setSelectQuery(selectQuery)
            setSelectedImages([])
            setExplanation('')
            document.getElementsByTagName('textarea')[0].value = ''
            setIsLoading(true)
            setTimeout(() => {
              setIsLoading(false)
              setIsSend(true)
              window.scrollTo(0, 0)
              setSubmitButtonDissabled(false)
            }, 1000)
          }
        })
      })
    }

  }
  const renderUserMessage = (message) => {
    //console.log(message)
    if (message.isReplied) {
      return (
        <>
          <span className="d-flex justify-content-end pr-1 pt-2" style={{ fontSize: '12px' }}>You:</span>
          <div className="jumbotron p-2">
            {!message.isReplied ? <div className="d-flex justify-content-end" style={{ fontSize: '10px' }}>Query: <b>{message.topic}</b></div> : null}
            <div className="d-flex justify-content-end pt-2" style={{ fontSize: '10px', textAlign: 'right', color: '#26292e' }}>
              <p style={{ "word-wrap": "break-word", width: '100%', fontSize: '10px', textAlign: 'left', color: '#26292e' }}>{message.explanation}</p>
            </div>

            {renderImagesMessages(message.images)}
            <div id="dateTime-message" className="d-flex justify-content-end">{new Date(parseInt(message.date)).toLocaleString()}</div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <span className="d-flex justify-content-end pr-1 pt-2" style={{ fontSize: '10px' }}>You:</span>
          <div className="jumbotron p-3">
            {!message.isReplied ? <div className="d-flex justify-content-end" style={{ fontSize: '14px' }}><b>Query:</b> <b>{message.topic}</b></div> : null}
            <div className="d-flex justify-content-end pt-2" style={{ fontSize: '12px', textAlign: 'right', color: '#26292e' }}>
              <p className="d-flex justify-content-end" style={{ "word-wrap": "break-word", width: '100%', fontSize: '10px', textAlign: 'left', color: '#26292e' }}>{message.explanation}</p>
            </div>
            {renderImagesMessages(message.images)}
            <div id="dateTime-message-main" className="d-flex justify-content-end">{new Date(parseInt(message.date)).toLocaleString()}</div>
          </div>
        </>
      )
    }
  }
  const renderUserMessageWeb = (message) => {
    //console.log(message)
    if (message.isReplied) {
      return (
        <>
          <span className="d-flex justify-content-end pr-1 pt-2" style={{ fontSize: '13x' }}>You:</span>
          <div className="jumbotron p-2">
            {!message.isReplied ? <div className="d-flex justify-content-end" style={{ fontSize: '13px' }}>Query: <b>{message.topic}</b></div> : null}
            <div className="d-flex justify-content-end pt-2" style={{ fontSize: '13px', textAlign: 'right', color: '#26292e' }}>
              <p className="d-flex justify-content-end" style={{ "word-wrap": "break-word", width: '100%', fontSize: '13px', textAlign: 'left', color: '#26292e' }}>{message.explanation}</p>
            </div>

            {renderImagesMessages(message.images)}
            <div id="dateTime-message-web" className="d-flex justify-content-end">{new Date(parseInt(message.date)).toLocaleString()}</div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <span className="d-flex justify-content-end pr-1 pt-2" style={{ fontSize: '13px' }}>You:</span>
          <div className="jumbotron p-3">
            {!message.isReplied ? <div className="d-flex justify-content-end" style={{ fontSize: '17px' }}><b>Query:</b> <b>{message.topic}</b></div> : null}
            <div className="d-flex justify-content-end pt-2" style={{ fontSize: '15px', textAlign: 'right', color: '#26292e' }}>
              <p className="d-flex justify-content-end" style={{ "word-wrap": "break-word", width: '100%', fontSize: '13px', textAlign: 'left', color: '#26292e' }}>{message.explanation}</p>
            </div>
            {renderImagesMessages(message.images)}
            <div id="dateTime-message-web" className="d-flex justify-content-end">{new Date(parseInt(message.date)).toLocaleString()}</div>
          </div>
        </>
      )
    }
  }
  const renderGivvyMessage = (message) => {
    return (
      <>
        <div className="d-flex justify-content-start">
          <img id="img-customer-givvy" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1704903198702.png" />
          <span className="d-flex justify-content-start pl-5" style={{ fontSize: '10px' }}>Givvy Customer Support:</span>
        </div>
        <div className="jumbotron p-3">
          {!message.isReplied ? <div className="d-flex justify-content-end" style={{ fontSize: '10px' }}>Query: <b>{message.topic}</b></div> : null}
          <div className="d-flex justify-content-start pt-2 pl-4" style={{ fontSize: '10px', textAlign: 'left', color: '#26292e' }}>
            {message.explanation}
          </div>
          {renderImagesMessages(message.images)}
          <div id="dateTime-message-main" className="d-flex justify-content-end">{new Date(parseInt(message.date)).toLocaleString()}</div>
        </div>
      </>
    )
  }
  const renderGivvyMessageWeb = (message) => {
    return (
      <>
        <div className="d-flex justify-content-start">
          <img id="img-customer-givvy-web" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1704903198702.png" />
          <span className="d-flex justify-content-start pl-5" style={{ fontSize: '13px' }}>Givvy Customer Support:</span>
        </div>
        <div className="jumbotron p-3">
          {!message.isReplied ? <div className="d-flex justify-content-end" style={{ fontSize: '13px' }}>Query: <b>{message.topic}</b></div> : null}
          <div className="d-flex justify-content-start pt-2 pl-4" style={{ fontSize: '13px', textAlign: 'left', color: '#26292e' }}>
            {message.explanation}
          </div>
          {renderImagesMessages(message.images)}
          <div id="dateTime-message-web" className="d-flex justify-content-end">{new Date(parseInt(message.date)).toLocaleString()}</div>
        </div>
      </>
    )
  }
  const renderMessages = () => {
    if (isMobile) {
      return (
        <>
          {renderUserMessage(selectQuery)}
          {selectQuery.replies.map((value) => {
            if (value.isByUser) {
              return (
                <>
                  {renderUserMessage(value)}
                </>
              )
            } else {
              return (
                <>
                  {renderGivvyMessage(value)}
                </>
              )
            }
          })}
        </>
      )
    } else {
      return (
        <>
          {renderUserMessageWeb(selectQuery)}
          {selectQuery.replies.map((value) => {
            if (value.isByUser) {
              return (
                <>
                  {renderUserMessageWeb(value)}
                </>
              )
            } else {
              return (
                <>
                  {renderGivvyMessageWeb(value)}
                </>
              )
            }
          })}
        </>
      )
    }

  }

  const renderImagesMessages = (images) => {
    return images.map((value) => {
      if (/\.png$/.test(value)) {
        return (
          <img className="mx-2" onClick={() => { setSelectedImage(value) }} style={{ width: "50px", height: '50px' }} src={value} />
        )
      } else {
        return (<img className="mx-2" onClick={() => { setSelectedImage(value) }} style={{ width: "50px", height: '50px', cursor: 'pointer' }} src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1707836560857.png" />)
      }
    })
  }
  const renderListOfMessages = () => {
    return queries.map((value) => {
      // console.log(value)
      let isNotSeen = value.replies.filter(f => !f.isSeenByUser && !f.isByUser).length
      return (
        <>
          <div className={`jumbotron`} onClick={() => { setSelectQuery(value); setSelectButton('responses'); seenReply(value) }}>
            {value.isResolved ? <div className="taskcomplete">
              <svg width="20" height="20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25 50C28.283 50 31.5339 49.3534 34.5671 48.097C37.6002 46.8406 40.3562 44.9991 42.6777 42.6777C44.9991 40.3562 46.8406 37.6002 48.097 34.5671C49.3534 31.5339 50 28.283 50 25C50 21.717 49.3534 18.4661 48.097 15.4329C46.8406 12.3998 44.9991 9.64379 42.6777 7.32233C40.3562 5.00087 37.6002 3.15938 34.5671 1.90301C31.5339 0.646644 28.283 -4.89212e-08 25 0C18.3696 9.88008e-08 12.0107 2.63392 7.32233 7.32233C2.63392 12.0107 0 18.3696 0 25C0 31.6304 2.63392 37.9893 7.32233 42.6777C12.0107 47.3661 18.3696 50 25 50ZM24.3556 35.1111L38.2444 18.4444L33.9778 14.8889L22.0333 29.2194L15.8528 23.0361L11.925 26.9639L20.2583 35.2972L22.4083 37.4472L24.3556 35.1111Z" fill="#170F49" />
              </svg>
            </div> : null}
            {isNotSeen > 0 ? <div className="taskcomplete"><svg width="20" height="20" viewBox="0 0 62 51" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M53.5455 17C55.7877 17 57.9382 16.1045 59.5237 14.5104C61.1093 12.9163 62 10.7543 62 8.5C62 6.24566 61.1093 4.08365 59.5237 2.48959C57.9382 0.895533 55.7877 0 53.5455 0C51.3032 0 49.1527 0.895533 47.5672 2.48959C45.9817 4.08365 45.0909 6.24566 45.0909 8.5C45.0909 10.7543 45.9817 12.9163 47.5672 14.5104C49.1527 16.1045 51.3032 17 53.5455 17ZM9.15909 5.66667H41.1793C40.6061 8.18783 40.8098 10.8247 41.7632 13.2267C42.7166 15.6286 44.3747 17.682 46.5169 19.1137L28.1818 28.7668L0.045091 13.9485C0.264196 11.7682 1.2495 9.73798 2.82396 8.22257C4.39842 6.70716 6.45881 5.80594 8.63491 5.68083L9.15909 5.66667ZM29.1625 33.048L51.7925 21.1282C53.3155 21.3396 54.864 21.2734 56.3636 20.9327V41.7917C56.3638 44.1437 55.4687 46.4067 53.862 48.1163C52.2553 49.826 50.0588 50.8527 47.7231 50.9858L47.2045 51H9.15909C6.81961 51.0002 4.56876 50.1003 2.86826 48.4849C1.16776 46.8696 0.146542 44.6613 0.0140912 42.313L0 41.7917V18.7227L27.2011 33.048C27.5037 33.2074 27.8402 33.2906 28.1818 33.2906C28.5234 33.2906 28.8599 33.2074 29.1625 33.048Z" fill="#170F49" />
            </svg>
            </div> : null}
            <div className="d-flex justify-content-between" style={{ fontSize: '10px' }}>
              <span>
                Query: <b>{value.topic}</b>
              </span>
              {value.replies.length > 0 ?
                <span style={{ fontWeight: "bold" }}>Responses({value.replies.filter(f => !f.isByUser).length})</span>
                : <span>Responses({value.replies.filter(f => !f.isByUser).length})</span>
              }
            </div>
            <div className="d-flex justify-content-start pt-2" style={{ fontSize: '10px', textAlign: 'left', color: '#26292e' }}>
              <p style={{ "word-wrap": "break-word", width: '100%', fontSize: '10px', textAlign: 'left', color: '#26292e' }}>{value.explanation}</p>
            </div>
            <div id="dateTime-message-list-web" className="d-flex justify-content-end">{new Date(parseInt(value.date)).toLocaleString()}</div>
          </div>
        </>
      )
    })
  }
  const renderListOfMessagesWeb = () => {
    return queries.map((value) => {
      // console.log(value)
      let isNotSeen = value.replies.filter(f => !f.isSeenByUser && !f.isByUser).length
      return (
        <>
          <div className={`jumbotron jumb-web`} onClick={() => { setSelectQuery(value); setSelectButton('responses'); seenReply(value) }}>
            {value.isResolved ? <div className="taskcomplete-web">
              <svg width="25" height="25" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25 50C28.283 50 31.5339 49.3534 34.5671 48.097C37.6002 46.8406 40.3562 44.9991 42.6777 42.6777C44.9991 40.3562 46.8406 37.6002 48.097 34.5671C49.3534 31.5339 50 28.283 50 25C50 21.717 49.3534 18.4661 48.097 15.4329C46.8406 12.3998 44.9991 9.64379 42.6777 7.32233C40.3562 5.00087 37.6002 3.15938 34.5671 1.90301C31.5339 0.646644 28.283 -4.89212e-08 25 0C18.3696 9.88008e-08 12.0107 2.63392 7.32233 7.32233C2.63392 12.0107 0 18.3696 0 25C0 31.6304 2.63392 37.9893 7.32233 42.6777C12.0107 47.3661 18.3696 50 25 50ZM24.3556 35.1111L38.2444 18.4444L33.9778 14.8889L22.0333 29.2194L15.8528 23.0361L11.925 26.9639L20.2583 35.2972L22.4083 37.4472L24.3556 35.1111Z" fill="#170F49" />
              </svg>

            </div> : null}
            {isNotSeen > 0 ? <div className="taskcomplete-web"><svg width="25" height="25" viewBox="0 0 62 51" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M53.5455 17C55.7877 17 57.9382 16.1045 59.5237 14.5104C61.1093 12.9163 62 10.7543 62 8.5C62 6.24566 61.1093 4.08365 59.5237 2.48959C57.9382 0.895533 55.7877 0 53.5455 0C51.3032 0 49.1527 0.895533 47.5672 2.48959C45.9817 4.08365 45.0909 6.24566 45.0909 8.5C45.0909 10.7543 45.9817 12.9163 47.5672 14.5104C49.1527 16.1045 51.3032 17 53.5455 17ZM9.15909 5.66667H41.1793C40.6061 8.18783 40.8098 10.8247 41.7632 13.2267C42.7166 15.6286 44.3747 17.682 46.5169 19.1137L28.1818 28.7668L0.045091 13.9485C0.264196 11.7682 1.2495 9.73798 2.82396 8.22257C4.39842 6.70716 6.45881 5.80594 8.63491 5.68083L9.15909 5.66667ZM29.1625 33.048L51.7925 21.1282C53.3155 21.3396 54.864 21.2734 56.3636 20.9327V41.7917C56.3638 44.1437 55.4687 46.4067 53.862 48.1163C52.2553 49.826 50.0588 50.8527 47.7231 50.9858L47.2045 51H9.15909C6.81961 51.0002 4.56876 50.1003 2.86826 48.4849C1.16776 46.8696 0.146542 44.6613 0.0140912 42.313L0 41.7917V18.7227L27.2011 33.048C27.5037 33.2074 27.8402 33.2906 28.1818 33.2906C28.5234 33.2906 28.8599 33.2074 29.1625 33.048Z" fill="#170F49" />
            </svg>
            </div> : null}
            <div className="d-flex justify-content-between" style={{ fontSize: '13px' }}>
              <span>
                Query: <b>{value.topic}</b>
              </span>
              {value.replies.filter(f => !f.isByUser).length > 0 ?
                <span style={{ fontWeight: "bold" }}>Responses({value.replies.filter(f => !f.isByUser).length})</span>
                : <span>Responses({value.replies.filter(f => !f.isByUser).length})</span>
              }
            </div>
            <div className="d-flex justify-content-start pt-2" style={{ fontSize: '13px', textAlign: 'left', color: '#26292e' }}>
              <p style={{ "word-wrap": "break-word", width: '100%', fontSize: '13px', textAlign: 'left', color: '#26292e' }}>{value.explanation}</p>
            </div>
            <div id="dateTime-message-list-web" className="d-flex justify-content-end">{new Date(parseInt(value.date)).toLocaleString()}</div>
          </div>
        </>
      )
    })
  }

  //https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703239235994.png
  //https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png
  var hash = window.location.hash;
  return (
    <QueriesHeader hideQueries={hideQueries} currQueries={queries} userId={values.id} isLoading={isLoading}></QueriesHeader>
  )
  if (isMobile) {
    return (<QueryList queries={queries}></QueryList>)
    // if (selectButton == 'responses') {
    //   return (
    //     <>
    //       {document.body.style.setProperty('background-color', 'white')}
    //       {isLoading ? <div className="loading">
    //         <Lottie animationData={groovyWalkAnimation} loop={true} />
    //       </div> : null}
    //       <div className="container-form-contact">
    //         {/* <div className="d-flex justify-content-end p-2">
    //           <button onClick={() => { localStorage.setItem('nigol', ''); window.location.pathname = '/support/login' }} className="btn logout-button">Logout</button>
    //         </div> */}
    //         <div className="d-flex justify-content-center pt-3">
    //           <p className="m-0" style={{ color: 'white', fontWeight: 600, textAlign: 'center' }}>Welcome, {values.name}</p>
    //         </div>
    //         <div className="d-flex justify-content-center">
    //           <p className="m-0" style={{ color: '#F7F7FB', fontWeight: 600, textAlign: 'center', fontSize: '8px' }}>Lets us know about the issue you are facing.</p>
    //         </div>
    //         <div className="d-flex justify-content-center mt-2">
    //         <p
    //           onClick={() => { hideQueries() }}
    //           className="m-0"
    //           style={{
    //             color: '#F7F7FB',
    //             fontWeight: 600,
    //             textAlign: 'center',
    //             fontSize: '15px',
    //             cursor: 'pointer', // Indica que es interactivo
    //             transition: 'all 0.3s ease', // Animación suave
    //             padding: '10px',
    //             borderRadius: '5px',
    //             backgroundColor: 'transparent' // Fondo transparente por defecto
    //           }}
    //           onMouseEnter={(e) => {
    //             e.target.style.backgroundColor = '#E2E2EA'; // Cambia el fondo al pasar el mouse
    //             e.target.style.color = '#000'; // Cambia el color del texto
    //             e.target.style.transform = 'scale(1.05)'; // Aumenta el tamaño ligeramente
    //           }}
    //           onMouseLeave={(e) => {
    //             e.target.style.backgroundColor = 'transparent'; // Vuelve al fondo original
    //             e.target.style.color = '#F7F7FB'; // Vuelve al color original
    //             e.target.style.transform = 'scale(1)'; // Restaura el tamaño
    //           }}
    //         >
    //           Return to Main Menu
    //         </p>
    //       </div>
    //         <div id="modal-container" className="container-outside-form p-2 pt-5">
    //           <div className="container-form p-4">
    //             <div className="d-flex justify-content-between">
    //               <div>
    //                 {/* <span style={{ fontWeight: "bold" }} className={`mx-3 ${selectButton == 'queries' ? "text-options" : "menuu"}`} onClick={() => { setSelectButton('queries'); setIsSend(false) }}>Create a query</span> */}
    //               </div>
    //               <div>
    //                 {/* <span style={{ fontWeight: "bold" }} className={`mx-3 ${(selectButton == 'list' || selectButton == 'responses') ? "text-options" : "menuu"}`} onClick={() => { setSelectButton('list'); setIsSend(false) }}>Your queries</span> */}
    //               </div>
    //             </div>
    //             <div>
    //               <div className="mt-3 px-3 pb-3 pt-1 custom-input-name">
    //                 {renderMessages()}
    //                 <Modal isOpen={selectedImage} toggle={() => { setSelectedImage(null) }} size="sm" className={""}>
    //                   {/* <ModalHeader toggle={selectedImage}><h3 style={{ color: 'red' }}>IMPORTANT!</h3></ModalHeader> */}
    //                   <ModalBody>
    //                     <div onClick={() => { setSelectedImage(null) }} className="btn btn-default my-2">close</div>
    //                     <img style={{ width: '100%', height: "100%" }} src={selectedImage} />
    //                   </ModalBody>
    //                 </Modal>
    //                 {selectQuery.replies.length == 0 ? <span className="d-flex justify-content-start" style={{ fontSize: "8px", color: "#26292e" }}>
    //                   Your query has no response yet.
    //                 </span> : null}
    //               </div>

    //               <div className="mt-3 px-3 pb-3 pt-1 custom-input-name">
    //                 <span className="" style={{ fontSize: '10px' }}>Your Reply <p className="text-p2 mb-2">Lets us know the details of your case or send us a screenshot of it.</p></span>
    //                 <div className="d-flex justify-content-center">
    //                   <textarea style={{ height: "150px" }} onChange={(e) => { setExplanation(e.target.value) }} type="text" className="form-control" placeholder="Type here" />
    //                 </div>
    //                 <div className="d-flex justify-content-center pt-2">
    //                   <div ondrop={(e) => { drop(e) }} ondragover={(e) => { allowDrop(e) }} className="upload-file-custom" onClick={() => { document.getElementById('upload').click() }}>
    //                     <div className="upload-file-custom-inside">
    //                       <div id="container-upload-image">
    //                         <img id="icon-download" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1704120194426.png"></img>
    //                         <p className="text-p">Drop your image here
    //                           <p className="text-p2">Supports: PNG,JPG,JPEG,WEBP</p></p>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <input id="upload" type="file" className="form-control" name="upload_file" multiple onChange={handleImage} />
    //                 </div>
    //                 {renderImages()}
    //               </div>

    //               {selectQuery.isResolved ? null : <div className="d-flex justify-content-center mt-4">
    //                 <div onClick={() => { resolveIssue() }} className="btn btn-custom-2-resolve mx-1">Issue is Resolved</div>
    //                 <div onClick={() => { sendReply() }} className="btn btn-custom-2" disabled={submitButtonDisabled}>Reply</div>
    //               </div>}
    //             </div>
    //           </div>
    //         </div>
    //         {/* <div className="d-flex justify-content-between">
    //         <div className="m-8">
    //           <div id="text-welcome" className="d-flex justify-content-start">Welcome!</div>
    //           <div id="text-welcome-1" className="d-flex justify-content-start">This is Givvy Customer Support Panel</div>
    //           <div id="text-welcome-2" className="d-flex justify-content-start mt-5">Let us know how we can assist you. In order to continue you must sign up with your Google account. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dolor urna, rhoncus a dapibus in, tempor a justo. Ut a ante mauris. Nunc fringilla, massa et vehicula elementum, ante tellus fringilla lorem, at viverra velit neque sit amet orci. Duis luctus fermentum leo vel venenatis. Aliquam erat volutpat.</div>
    //           <div id="text-welcome-button-login" className="btn btn-default mt-5">
    //             <p id="text-welcome-button-login-txt">Sign up with Google</p>
    //           </div>
    //         </div>
    //         <div className="mt-4">
    //           <img id="main-login-image-2" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png"></img>
    //         </div>

    //       </div> */}
    //       </div>
    //       {/* <img id="main-login-image" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703239235994.png"></img>
    //     <img id="main-login-image-2" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png"></img>
    //     <div id="text-welcome" className="d-flex justify-content-start">Welcome!</div>
    //     <div id="text-welcome-1" className="d-flex justify-content-start">This is Givvy Customer Support Panel</div>
    //     <div id="text-welcome-2" className="d-flex justify-content-start">Let us know how we can assist you. In order to continue you must sign up with your Google account. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dolor urna, rhoncus a dapibus in, tempor a justo. Ut a ante mauris. Nunc fringilla, massa et vehicula elementum, ante tellus fringilla lorem, at viverra velit neque sit amet orci. Duis luctus fermentum leo vel venenatis. Aliquam erat volutpat.</div> */}
    //     </>
    //   );
    // } else if (selectButton == 'list') {
    //   return (
    //     <div className="container-form-contact">
    //       {/* <div className="d-flex justify-content-end p-2">
    //         <button onClick={() => { localStorage.setItem('nigol', ''); window.location.pathname = '/support/login' }} className="btn logout-button">Logout</button>
    //       </div> */}
    //       <div className="d-flex justify-content-center pt-3">
    //         <p className="m-0" style={{ color: 'white', fontWeight: 600, textAlign: 'center' }}>Welcome, {values.name}</p>
    //       </div>
    //       <div className="d-flex justify-content-center">
    //         <p className="m-0" style={{ color: '#F7F7FB', fontWeight: 600, textAlign: 'center', fontSize: '8px' }}>Lets us know about the issue you are facing.</p>
    //       </div>
    //       <div className="d-flex justify-content-center mt-2">
    //         <p
    //           onClick={() => { hideQueries() }}
    //           className="m-0"
    //           style={{
    //             color: '#F7F7FB',
    //             fontWeight: 600,
    //             textAlign: 'center',
    //             fontSize: '15px',
    //             cursor: 'pointer', // Indica que es interactivo
    //             transition: 'all 0.3s ease', // Animación suave
    //             padding: '10px',
    //             borderRadius: '5px',
    //             backgroundColor: 'transparent' // Fondo transparente por defecto
    //           }}
    //           onMouseEnter={(e) => {
    //             e.target.style.backgroundColor = '#E2E2EA'; // Cambia el fondo al pasar el mouse
    //             e.target.style.color = '#000'; // Cambia el color del texto
    //             e.target.style.transform = 'scale(1.05)'; // Aumenta el tamaño ligeramente
    //           }}
    //           onMouseLeave={(e) => {
    //             e.target.style.backgroundColor = 'transparent'; // Vuelve al fondo original
    //             e.target.style.color = '#F7F7FB'; // Vuelve al color original
    //             e.target.style.transform = 'scale(1)'; // Restaura el tamaño
    //           }}
    //         >
    //           Return to Main Menu
    //         </p>
    //       </div>
    //       <div id="modal-container" className="container-outside-form p-2 pt-5">
    //         <div className="container-form p-4">
    //           <div className="d-flex justify-content-between">
    //             <div>
    //               {/* <span style={{ fontWeight: "bold" }} className={`mx-3 ${selectButton == 'queries' ? "text-options" : "menuu"}`} onClick={() => { setSelectButton('queries'); setIsSend(false) }}>Create a query</span> */}
    //             </div>
    //             <div>
    //               {/* <span style={{ fontWeight: "bold" }} className={`mx-3 ${selectButton == 'list' ? "text-options" : "menuu"}`} onClick={() => { setSelectButton('list'); setIsSend(false) }}>Your queries</span> */}
    //             </div>
    //           </div>
    //           <div>
    //             {/* {renderListOfMessages()} */}
    //             <div className="mt-3 px-3 pb-3 pt-1 custom-input-name">
    //               <span style={{ fontSize: "13px" }} className="d-flex justify-content-start">
    //                 Your Queries
    //               </span>
    //               <span style={{ fontSize: "9px", color: '#26292e' }} className="d-flex justify-content-start">
    //                 Click on the query to see its details and responses
    //               </span>
    //               <div className="responsesList pt-3">
    //                 {renderListOfMessages()}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       {/* <div className="d-flex justify-content-between">
    //     <div className="m-8">
    //       <div id="text-welcome" className="d-flex justify-content-start">Welcome!</div>
    //       <div id="text-welcome-1" className="d-flex justify-content-start">This is Givvy Customer Support Panel</div>
    //       <div id="text-welcome-2" className="d-flex justify-content-start mt-5">Let us know how we can assist you. In order to continue you must sign up with your Google account. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dolor urna, rhoncus a dapibus in, tempor a justo. Ut a ante mauris. Nunc fringilla, massa et vehicula elementum, ante tellus fringilla lorem, at viverra velit neque sit amet orci. Duis luctus fermentum leo vel venenatis. Aliquam erat volutpat.</div>
    //       <div id="text-welcome-button-login" className="btn btn-default mt-5">
    //         <p id="text-welcome-button-login-txt">Sign up with Google</p>
    //       </div>
    //     </div>
    //     <div className="mt-4">
    //       <img id="main-login-image-2" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png"></img>
    //     </div>

    //   </div> */}
    //     </div>
    //   )
    // }
  } else {
    if (selectButton == 'responses') {
      return (
        <>
          {document.body.style.setProperty('background-color', 'white')}
          {isLoading ? <div className="loading-web">
            <Lottie animationData={groovyWalkAnimation} loop={true} />
          </div> : null}
          <div id="form-web" className="container-form-contact">
            {/* <div className="d-flex justify-content-end p-2">
              <button onClick={() => { localStorage.setItem('nigol', ''); window.location.pathname = '/support/login' }} className="btn logout-button">Logout</button>
            </div> */}
            <div className="d-flex justify-content-center pt-3">
              <p className="m-0" style={{ color: 'white', fontWeight: 600, textAlign: 'center', fontSize: '25px' }}>Welcome, {values.name}</p>
            </div>
            <div className="d-flex justify-content-center">
              <p className="m-0" style={{ color: '#F7F7FB', fontWeight: 600, textAlign: 'center', fontSize: '15px' }}>Lets us know about the issue you are facing.</p>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <p
                onClick={() => { hideQueries() }}
                className="m-0"
                style={{
                  color: '#F7F7FB',
                  fontWeight: 600,
                  textAlign: 'center',
                  fontSize: '15px',
                  cursor: 'pointer', // Indica que es interactivo
                  transition: 'all 0.3s ease', // Animación suave
                  padding: '10px',
                  borderRadius: '5px',
                  backgroundColor: 'transparent' // Fondo transparente por defecto
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#E2E2EA'; // Cambia el fondo al pasar el mouse
                  e.target.style.color = '#000'; // Cambia el color del texto
                  e.target.style.transform = 'scale(1.05)'; // Aumenta el tamaño ligeramente
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'transparent'; // Vuelve al fondo original
                  e.target.style.color = '#F7F7FB'; // Vuelve al color original
                  e.target.style.transform = 'scale(1)'; // Restaura el tamaño
                }}
              >
                Return to Main Menu
              </p>
            </div>

            <div id="modal-container" className="container-outside-form p-2 pt-5">
              <div className="container-form p-4">
                <div className="d-flex justify-content-between">
                  <div>
                    {/* <span style={{ fontWeight: "bold" }} className={`mx-3 ${selectButton == 'queries' ? "text-options" : "menuu"}`} onClick={() => { window.history.pushState({ page: selectButton }, null, null); setSelectButton('queries'); setIsSend(false) }}>Create a query</span> */}
                  </div>
                  <div>
                    {/* <span style={{ fontWeight: "bold" }} className={`mx-3 ${(selectButton == 'list' || selectButton == 'responses') ? "text-options" : "menuu"}`} onClick={() => { window.history.pushState({ page: selectButton }, null, null); setSelectButton('list'); setIsSend(false) }}>Your queries</span> */}
                  </div>
                </div>
                <div>
                  <div className="mt-3 px-3 pb-3 pt-1 custom-input-name">
                    {renderMessages()}
                    <Modal isOpen={selectedImage} toggle={() => { setSelectedImage(null) }} size="sm" className={""}>
                      {/* <ModalHeader toggle={selectedImage}><h3 style={{ color: 'red' }}>IMPORTANT!</h3></ModalHeader> */}
                      <ModalBody>
                        <div onClick={() => { setSelectedImage(null) }} className="btn btn-default my-2">close</div>
                        <img style={{ width: '100%', height: "100%" }} src={selectedImage} />
                      </ModalBody>
                    </Modal>
                    {selectQuery.replies.length == 0 ? <span className="d-flex justify-content-start" style={{ fontSize: "12px", color: "#26292e" }}>
                      Your query has no response yet.
                    </span> : null}
                  </div>

                  <div className="mt-3 px-3 pb-3 pt-1 custom-input-name">
                    {selectQuery.isResolved ? null : <><span className="" style={{ fontSize: '13px' }}>Your Reply <p style={{ fontSize: '13px' }} className="text-p2 mb-2">Lets us know the details of your case or send us a screenshot of it.</p></span>
                      <div className="d-flex justify-content-center">
                        <textarea style={{ height: "150px" }} onChange={(e) => { setExplanation(e.target.value) }} type="text" className="form-control" placeholder="Type here" />
                      </div></>}
                    <div className="d-flex justify-content-center pt-2">
                      {selectQuery.isResolved ? null : <>
                        <div ondrop={(e) => { drop(e) }} ondragover={(e) => { allowDrop(e) }} className="upload-file-custom-web" onClick={() => { document.getElementById('upload').click() }}>
                          <div className="upload-file-custom-inside-web">
                            <div id="container-upload-image-web">
                              <img id="icon-download-web" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1704120194426.png"></img>
                              <p className="text-p-web">Drop your image here
                                <p className="text-p2-web">Supports: PNG,JPG,JPEG,WEBP</p></p>
                            </div>
                          </div>
                        </div>
                        <input id="upload" type="file" className="form-control" name="upload_file" multiple onChange={handleImage} />
                      </>}
                    </div>
                    {renderImages()}
                  </div>

                  {selectQuery.isResolved ? null : <div className="d-flex justify-content-center mt-4">
                    <div onClick={() => { resolveIssue() }} className="btn btn-custom-2-resolve-web mx-1">Issue is Resolved</div>
                    <div onClick={() => { sendReply() }} className="btn btn-custom-2-web" disabled={submitButtonDisabled}>Reply</div>
                  </div>}
                </div>
              </div>
            </div>
            {/* <div className="d-flex justify-content-between">
            <div className="m-8">
              <div id="text-welcome" className="d-flex justify-content-start">Welcome!</div>
              <div id="text-welcome-1" className="d-flex justify-content-start">This is Givvy Customer Support Panel</div>
              <div id="text-welcome-2" className="d-flex justify-content-start mt-5">Let us know how we can assist you. In order to continue you must sign up with your Google account. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dolor urna, rhoncus a dapibus in, tempor a justo. Ut a ante mauris. Nunc fringilla, massa et vehicula elementum, ante tellus fringilla lorem, at viverra velit neque sit amet orci. Duis luctus fermentum leo vel venenatis. Aliquam erat volutpat.</div>
              <div id="text-welcome-button-login" className="btn btn-default mt-5">
                <p id="text-welcome-button-login-txt">Sign up with Google</p>
              </div>
            </div>
            <div className="mt-4">
              <img id="main-login-image-2" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png"></img>
            </div>
    
          </div> */}
          </div>
          {/* <img id="main-login-image" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703239235994.png"></img>
        <img id="main-login-image-2" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png"></img>
        <div id="text-welcome" className="d-flex justify-content-start">Welcome!</div>
        <div id="text-welcome-1" className="d-flex justify-content-start">This is Givvy Customer Support Panel</div>
        <div id="text-welcome-2" className="d-flex justify-content-start">Let us know how we can assist you. In order to continue you must sign up with your Google account. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dolor urna, rhoncus a dapibus in, tempor a justo. Ut a ante mauris. Nunc fringilla, massa et vehicula elementum, ante tellus fringilla lorem, at viverra velit neque sit amet orci. Duis luctus fermentum leo vel venenatis. Aliquam erat volutpat.</div> */}
        </>
      );
    } else if (selectButton == 'list') {
      return (
        <div id="form-web" className="container-form-contact">
          {/* <div className="d-flex justify-content-end p-2">
            <button onClick={() => { localStorage.setItem('nigol', ''); window.location.pathname = '/support/login' }} className="btn logout-button">Logout</button>
          </div> */}
          <div className="d-flex justify-content-center pt-3">
            <p className="m-0" style={{ color: 'white', fontWeight: 600, textAlign: 'center', fontSize: '25px' }}>Welcome, {values.name}</p>
          </div>
          <div className="d-flex justify-content-center">
            <p className="m-0" style={{ color: '#F7F7FB', fontWeight: 600, textAlign: 'center', fontSize: '15px' }}>Lets us know about the issue you are facing.</p>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <p
              onClick={() => { hideQueries() }}
              className="m-0"
              style={{
                color: '#F7F7FB',
                fontWeight: 600,
                textAlign: 'center',
                fontSize: '15px',
                cursor: 'pointer', // Indica que es interactivo
                transition: 'all 0.3s ease', // Animación suave
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: 'transparent' // Fondo transparente por defecto
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#E2E2EA'; // Cambia el fondo al pasar el mouse
                e.target.style.color = '#000'; // Cambia el color del texto
                e.target.style.transform = 'scale(1.05)'; // Aumenta el tamaño ligeramente
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent'; // Vuelve al fondo original
                e.target.style.color = '#F7F7FB'; // Vuelve al color original
                e.target.style.transform = 'scale(1)'; // Restaura el tamaño
              }}
            >
              Return to Main Menu
            </p>
          </div>
          <div id="modal-container" className="container-outside-form p-2 pt-5">
            <div className="container-form p-4">
              <div className="d-flex justify-content-between">
                <div>
                  {/* <span style={{ fontWeight: "bold" }} className={`mx-3 ${selectButton == 'queries' ? "text-options" : "menuu"}`} onClick={() => { window.history.pushState({ page: selectButton }, null, null); setSelectButton('queries'); setIsSend(false) }}>Create a query</span> */}
                </div>
                <div>
                  {/* <span style={{ fontWeight: "bold" }} className={`mx-3 ${selectButton == 'list' ? "text-options" : "menuu"}`} onClick={() => { window.history.pushState({ page: selectButton }, null, null); setSelectButton('list'); setIsSend(false) }}>Your queries</span> */}
                </div>
              </div>
              <div>
                {/* {renderListOfMessages()} */}
                <div className="mt-3 px-3 pb-3 pt-1 custom-input-name">
                  <span style={{ fontSize: "15px" }} className="d-flex justify-content-start">
                    Your Queries
                  </span>
                  <span style={{ fontSize: "12px", color: '#26292e' }} className="d-flex justify-content-start">
                    Click on the query to see its details and responses
                  </span>
                  <div className="responsesList pt-4">
                    {renderListOfMessagesWeb()}

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-between">
        <div className="m-8">
          <div id="text-welcome" className="d-flex justify-content-start">Welcome!</div>
          <div id="text-welcome-1" className="d-flex justify-content-start">This is Givvy Customer Support Panel</div>
          <div id="text-welcome-2" className="d-flex justify-content-start mt-5">Let us know how we can assist you. In order to continue you must sign up with your Google account. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dolor urna, rhoncus a dapibus in, tempor a justo. Ut a ante mauris. Nunc fringilla, massa et vehicula elementum, ante tellus fringilla lorem, at viverra velit neque sit amet orci. Duis luctus fermentum leo vel venenatis. Aliquam erat volutpat.</div>
          <div id="text-welcome-button-login" className="btn btn-default mt-5">
            <p id="text-welcome-button-login-txt">Sign up with Google</p>
          </div>
        </div>
        <div className="mt-4">
          <img id="main-login-image-2" src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703598852139.png"></img>
        </div>
  
      </div> */}
        </div>
      )
    }
  }

}

