import React, { useState } from "react";
import './queryList.css'
const QueryList = ({ queries, selectQuery, hideQueries }) => {
  return (
    <>
      <div className="queries2">
        <div className="dot-top-right"></div>
        <div className="dot-bottom-left"></div>
        <h3 className="queries-title">Your Queries</h3>
        <p className="queries-subtitle">
          CLICK ON THE QUERY TO SEE ITS DETAILS AND RESPONSES
        </p>
        {queries.length > 0 && queries.map((value) => {
          let isResolved = {}
          if (!value.isResolved) {
            isResolved = { opacity: 0 }
          }
          return (
            <div onClick={() => { selectQuery(value) }} className="query-item">
              <div className="query-dot-top-right"></div>
              <div className="query-header">
                <span className="query-title">{value.topic}</span>
                <span className="query-responses">Responses ({value.replies.length})
                  <img className="resolved-icon px-2" style={isResolved} src={require("./assets/resolved.png")}></img>
                </span>
              </div>
              <div className="query-body">
                <p>
                  {value.explanation}
                </p>
                <p className="query-user">From User: {value.username}</p>
                <div className="d-flex justify-content-end">
                  <span className="query-date">{new Date(parseInt(value.date)).toLocaleDateString()}</span>
                </div>
              </div>
            </div>
          )
        })}

      </div>

      <button onClick={() => { hideQueries() }} className="go-back-button">GO BACK</button>
    </>
  );
};

export default QueryList;
