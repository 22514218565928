import React, { Component } from 'react';
import { Redirect, Router, Route } from 'react-router-dom';
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomNavbar from './components/Navbar/Navbar';
import Feed from './components/Feed/Feed';
import { history } from './history';
import {
  isMobile
} from "react-device-detect";
import Winners from './components/AdminPanel/Winners';
import Contact from './components/Contact/Contact';
import ContactAS from './components/Contact/ContactAS';
import ContactTM from './components/Contact/ContactTM';
import AppAds from './components/appAds.jsx'
import LoginSupport from './components/supportPages/LoginSupport';
import ContactForm from './components/supportPages/ContactForm';
import axios from 'axios';
import ChatBotIndex from './components/supportPages/ChatBot/ChatBotIndex';
import NewContactForm from './components/supportPages/NewContactForm/NewContactForm';
const fs = require('fs')

class App extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {



  }

  render() {
    setInterval(() => {
      let dateNow = new Date(Date.now());
      let hoursAndMinutes = {
        hours: dateNow.getHours(),
        minutes: dateNow.getMinutes()
      }
      if (hoursAndMinutes.hours == 20 && (hoursAndMinutes.minutes > 33 && hoursAndMinutes.minutes < 35)) {
      }
    }, 6000)
    let classPrivacy = "privacy-policy";
    let classTerms = "terms-conditions";
    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    }

    // if (isMobile && (!/captcha|statistika|ani|admin/.test(document.URL))) {
    //   {
    //     setTimeout(() => {
    //       document.getElementById('store').click();
    //     }, 1000);
    //   }
    //   return (<a id='store' href={getMobileOperatingSystem() === "iOS"
    //     ? 'https://apps.apple.com/us/app/givvy-game/id1506358028' : 'https://play.google.com/store/apps/details?id=com.givvy'}></a>)

    // }
    if (/captcha/.test(document.URL) || /privacy-policy/.test(document.URL)) {
      classPrivacy = classPrivacy + " hidden";
      classTerms = classPrivacy + " hidden";
    }
    let links = [
      "/home",
      "/",
      "/generator-da-moje-vsichki-da-teglqt-nagradi",
      "/privacy-policy",
      "/statistika-koqto-mojem-samo-nie-da-vidim-zashtoto-sme-gotini",
      "/referalLink/id=",
      "/app-ads.txt",
      "/captcha",
      "/ani-tova-e-samo-za-teb-zashtoto-si-gotina",
      "/admin-za-podaraci",
      "/feedTest",
      '/admin-video',
      '/contact',
      '/contact_us',
      '/contact-us',
      '/app-ads',
      '/appworld',
      '/support/login',
      '/support/contact',
      '/support/chatbot'
    ];
    // console.log(history.location.pathname)

    if (links.toString().match(history.location.pathname.replace(/id=.*/, '')) || links.includes(history.location.pathname)) {
      if (history.location.pathname == '/app-ads') {
        return (
          <div className="App">
            <Router history={history}>
              <div>
                <Route exact path="/app-ads" component={AppAds} />
                <Route exact path="/support/login" component={NewContactForm} />
                <Route exact path="/support/contact" component={NewContactForm} />
              </div>
            </Router>
          </div>
        );
      } else if (history.location.pathname == '/support/login' || history.location.pathname == '/support/contact' || history.location.pathname == '/support/chatbot') {
        return (
          <div className="App">
            <Router history={history}>
              <div>
                <Route exact path="/app-ads" component={AppAds} />
                <Route exact path="/support/chatbot" component={NewContactForm} />
                <Route exact path="/support/login" component={NewContactForm} />
                <Route exact path="/support/contact" component={NewContactForm} />
              </div>
            </Router>
          </div>
        )
      } else if (history.location.pathname == '/appworld') {
        return (

          <div className="container-cut">
            <div className="d-flex justify-content-start ">
              <h1 className="app">APPWORLD</h1>
            </div>
            <div className="d-flex justify-content-start ">
              <h1 className="ltd">LTD</h1>
            </div>
            <div className="d-flex justify-content-start text-cut mb-5">
              <p className="mb-5">Welcome to  AppWorld!
                We are specialising in creating custom Android apps with integrated ads. We have created awesome games specifically designed to grab your attention and make you feel happy!
                We have a know-how to generate revenue through strategic ad placements and offer walls.
              </p>
            </div>
            <div className="d-flex justify-content-end ">
              <img className="img-cut" alt=""
                src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1689269708894.png" />
            </div>

            <div className="d-flex justify-content-center mt-5">
              <h1 className="title-cut mt-5">Our Apps</h1>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <div className="cnt-cut">
                <div className="d-flex justify-content-center">
                  <img className="pic-small" alt=""
                    src="https://play-lh.googleusercontent.com/DtAW4wxd7X5Dnpg8uRL8hboub30C289s1rmQrQsS0VnlFKNCjpLEDZNPUH4_HdDZ5Oo=w240-h480-rw" />
                </div>
                <div className="d-flex justify-content-center">
                  <h2 className="text-color">Focus on Productivity</h2>
                </div>
                <div className="d-flex justify-content-center text-p">
                  <p className="text-color">Improve yourself. Stay Focused. Stay concentrated. Have a better sleep!
                    The new amazing focus app helps you to beat phone addiction,designed to elevate your productivity, enhance your focus, and concentration and improve your sleep quality by awesome relaxation sounds. </p>
                </div>
                <div className="d-flex justify-content-center">
                  <img className="pic-small-store" alt=""
                    onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=com.givvy.focusapp" }}
                    // onClick="myFunction(`https://play.google.com/store/apps/details?id=com.givvy.focusapp`)"
                    src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1689316514218.png" />
                </div>
              </div>
              <div className="cnt-cut">
                <div className="d-flex justify-content-center">
                  <img className="pic-small" alt=""
                    src="https://play-lh.googleusercontent.com/9t66GBCIfwoVhOIUBteWEHKQuwogJDXBBFdjCE8WhRDOt_pLVVWlX1BptVuxWrA9B2E=w240-h480-rw" />
                </div>
                <div className="d-flex justify-content-center">
                  <h2 className="text-color">Tree garden</h2>
                </div>
                <div className="d-flex justify-content-center text-p">
                  <p className="text-color">Grow your tree today! Click on it as much as you can!
                    Reach the sky by growing your tree!
                    Grow a big tree, reach out the sky and enjoy!
                    Use fertilizers to grow your tree! Use Gardeners to help you grow an awesome tree!
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <img className="pic-small-store" alt=""
                    onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=com.appearnings.moneytree" }}
                    src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1689316514218.png" />
                </div>
              </div>
              <div className="cnt-cut">
                <div className="d-flex justify-content-center">
                  <img className="pic-small" alt=""
                    src="https://play-lh.googleusercontent.com/F4ZnIdZ-8nc6KbxX49sHRRDkWtsaFW1i7-P2R2CIr6yc1vtyMtx6eWcBFsWSyhiCTUU=w240-h480-rw" />
                </div>
                <div className="d-flex justify-content-center">
                  <h2 className="text-color">Video Tube
                  </h2>
                </div>
                <div className="d-flex justify-content-center text-p">
                  <p className="text-color">Video Tube is the newest app to listen your favourite songs and watch videos!

                    Checkout millions of songs and videos on our app! </p>
                </div>
                <div className="d-flex justify-content-center">
                  <img className="pic-small-store" alt=""
                    onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=com.appearnings.videotube" }}
                    src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1689316514218.png" />
                </div>
              </div>
              <div className="cnt-cut">
                <div className="d-flex justify-content-center">
                  <img className="pic-small" alt=""
                    src="https://play-lh.googleusercontent.com/APHI4JgxbfZVkkmjqqfCli_sdr2mLUoF8W-43venpslRD6beEv-ae1IgaKxapx1eRG8=w240-h480-rw" />
                </div>
                <div className="d-flex justify-content-center">
                  <h2 className="text-color">Ice Park
                  </h2>
                </div>
                <div className="d-flex justify-content-center text-p">
                  <p className="text-color">Build your perfect Ice Park, enjoy growing your animals and earn money today! Unlock levels and upgrade! Buy a manager and make it easier for you to upgade and make money!
                    Want to earn more money rewards?
                    Earn real money rewards today! 💰Hottest Paying Money app!
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=com.appearnings.icepark" }}
                    className="pic-small-store" alt=""
                    src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1689316514218.png" />
                </div>
              </div>
            </div>
          </div>

        );
      } else {
        window.location.href = 'https://givvyapps.com/'
        // return (

        //   <div className="container-cut">
        //     <div className="d-flex justify-content-start ">
        //       <h1 className="app">GIVVY</h1>
        //     </div>
        //     <div className="d-flex justify-content-start ">
        //       <h1 className="ltd">LTD</h1>
        //     </div>
        //     <div className="d-flex justify-content-start text-cut mb-5">
        //       <p className="mb-5">Welcome to  Givvy!
        //         We are specialising in creating custom Android apps with integrated ads. We have created awesome games specifically designed to grab your attention and make you feel happy!
        //         We have a know-how to generate revenue through strategic ad placements and offer walls.
        //       </p>
        //     </div>
        //     <div className="d-flex justify-content-end ">
        //       <img className="img-cut" alt=""
        //         src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1689269708894.png" />
        //     </div>

        //     <div className="d-flex justify-content-center mt-5">
        //       <h1 className="title-cut mt-5">Our Apps</h1>
        //     </div>

        //     <div className="d-flex justify-content-center mt-4">
        //       <div className="cnt-cut">
        //         <div className="d-flex justify-content-center">
        //           <img className="pic-small" alt=""
        //             src="https://play-lh.googleusercontent.com/tkQ5JsOc8xyKI7P6TC5OvJRwdccL6TGsYTeeAKbwYIbFKxGjtwwWT_RVWR4x2m3QYQ=w240-h480-rw" />
        //         </div>
        //         <div className="d-flex justify-content-center">
        //           <h2 className="text-color">Social Duels</h2>
        //         </div>
        //         <div className="d-flex justify-content-center text-p">
        //           <p className="text-color">Givvy Social Duels 💰 The first cash app 📲 that pays you REAL MONEY for uploading and voting on awesome photos. Download now and earn easy money online! 🔝 If you searching for a reliable and rewarding app where you can effortlessly earn cash and money, then Givvy Social Duels is the right choice!
        //             When it comes to earning </p>
        //         </div>
        //         <div className="d-flex justify-content-center">
        //           <img className="pic-small-store" alt=""
        //             onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=com.make.money.earn.cash.social.duels.paying.surveys&hl=en_US" }}
        //             src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1689316514218.png" />
        //         </div>
        //       </div>
        //       <div className="cnt-cut">
        //         <div className="d-flex justify-content-center">
        //           <img className="pic-small" alt=""
        //             src="https://play-lh.googleusercontent.com/nGzzD--3ihysNLMP1dIGO5jOm4UMgLO4uV3NfJkZp-WGz2eE9VYnppxSGnozx-Nv0g=s48-rw" />
        //         </div>
        //         <div className="d-flex justify-content-center">
        //           <h2 className="text-color">Givvy Social</h2>
        //         </div>
        //         <div className="d-flex justify-content-center text-p">
        //           <p className="text-color">Safety starts with understanding how developers collect and share your data. Data privacy and security practices may vary based on your use, region, and age. The developer provided this information and may update it over time.
        //           </p>
        //         </div>
        //         <div className="d-flex justify-content-center">
        //           <img className="pic-small-store" alt=""
        //             onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=com.appsession.videos&hl=en&gl=US" }}

        //             src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1689316514218.png" />
        //         </div>
        //       </div>
        //       <div className="cnt-cut">
        //         <div className="d-flex justify-content-center">
        //           <img className="pic-small" alt=""
        //             src="https://play-lh.googleusercontent.com/PPhI4PZKv3qxnSsxdcBdRt_fYWA4EaNuch2RYSgyPTWgUS7GzJfiQzMc-FzBnwoVgmAS=w240-h480-rw" />
        //         </div>
        //         <div className="d-flex justify-content-center">
        //           <h2 className="text-color">Givvy Short Reels
        //           </h2>
        //         </div>
        //         <div className="d-flex justify-content-center text-p">
        //           <p className="text-color">We are excited to introduce the inaugural release of the Money Reels app, where you can turn your time spent watching reels into a great experience! This release marks the beginning of a journey! </p>
        //         </div>
        //         <div className="d-flex justify-content-center">
        //           <img className="pic-small-store" alt=""
        //             onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=com.givvycafe&hl=en&gl=US" }}
        //             src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1689316514218.png" />
        //         </div>
        //       </div>
        //       <div className="cnt-cut">
        //         <div className="d-flex justify-content-center">
        //           <img className="pic-small" alt=""
        //             src="https://play-lh.googleusercontent.com/cc5IyoliEeZVkzTfL2ZWX9S34N39GX3EiQRvV9tvmdW9NUZE3leWVQc1_HIvOQDjpIyS=w240-h480-rw" />
        //         </div>
        //         <div className="d-flex justify-content-center">
        //           <h2 className="text-color">Money Mate
        //           </h2>
        //         </div>
        //         <div className="d-flex justify-content-center text-p">
        //           <p className="text-color">We are excited to introduce the inaugural release of the Money Mate app, where you can play awesome games and invite your friends to earn money This release marks the beginning of a journey! </p>
        //         </div>
        //         <div className="d-flex justify-content-center">
        //           <img
        //             onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=com.appearnings.icepark&hl=en&gl=US" }}
        //             className="pic-small-store" alt=""
        //             src="https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1689316514218.png" />
        //         </div>
        //       </div>
        //     </div>
        //   </div>

        // );
      }
    } else {
      return (<Router history={history}>
        <div>
          not found
        </div>
      </Router>);
    }
  }
}

export default App;
