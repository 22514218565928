import React from "react";
import Axios from 'axios';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary:", JSON.stringify(error), JSON.stringify(errorInfo));
    const uuid = localStorage.getItem("uuid")
    Axios.post(`https://givvy-backend-site.herokuapp.com/saveErrorFromChatBot`, { error: JSON.stringify(error), errorInfo: JSON.stringify(errorInfo), userId: uuid }).then((res) => {
      console.log(res)
    })
  }

  render() {
    if (this.state.hasError) {
      return <h2>Something went wrong. Please try again later.</h2>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
