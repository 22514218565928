import React, { useEffect, useRef, useState } from "react";
import './queryList.css'
import axios from "axios"
const QueryDetails = ({ query, selectQuery, userId }) => {
  console.log(query)
  const [explanation, setExplanation] = useState('')
  const [selectedImages, setSelectedImages] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMain, setIsLoadingMain] = useState(true);
  const [replies, setReplies] = useState([])


  const fileInputRef = useRef(null);
  useEffect(() => {
    axios.post('https://givvy-backend-site.herokuapp.com/getRepliesFromQuery', { queryId: query._id }).then((res) => {
      setReplies(res.data.result)
      setIsLoadingMain(false)
    })
  }, [])
  const handleClick = () => {
    fileInputRef.current.click(); // Activa el input de archivo
  };

  const handleImage = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to Array

    const validFiles = files.filter(file => file.size <= 5 * 1024 * 1024); // Filter files by size
    const oversizedFiles = files.filter(file => file.size > 5 * 1024 * 1024); // Separate oversized files

    if (oversizedFiles.length > 0) {
      alert("Some files are too large. Maximum allowed size is 5 MB.");
    }

    const imagePromises = validFiles.map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    Promise.all(imagePromises).then(images => {
      setSelectedImages(prevImages => [...prevImages, ...images]); // Append new images
    });
  }
  const resolveIssue = (res) => {
    // console.log(res)
    let check = window.confirm(`Are you certain that the problem has been resolved?`);
    if (check) {
      axios.post('https://givvy-backend-site.herokuapp.com/resolvedIssue', { queryId: query._id }).then((res) => {
        alert('Issue was resolved')
        // setSelectQuery(res.data.result)
        selectQuery(null)
        // getQueriesByUser(values)
      })
    }
  }
  const sendReply = () => {
    setIsLoading(true); // 2000ms = 2 seconds
    axios.post('https://givvy-backend-site.herokuapp.com/replyQuery', {
      images: selectedImages,
      userId: userId,
      queryId: query._id,
      explanation: explanation,
      isByUser: true
    }).then((res) => {
      // console.log(res)
      if (res && res.status == 200) {
        query.replies.push(res.data.result)
        setSelectedImages([])
        setExplanation('')
        document.getElementsByTagName('textarea')[0].value = ''
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    })
  }
  const removeImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  if (isLoadingMain) {
    return (<div className="robot-container">
    <img src={require('./assets/robot-image.png')} alt="Robot Assistant" />
  </div>)
  } else {
    return (
      <>
        <div className="queries2">
          <div className="dot-top-right"></div>
          <div className="dot-bottom-left"></div>
          <h3 className="queries-title">Your Queries: <span className="ml-1">{query.topic}</span> </h3>
          <p className="queries-subtitle">
            HERE ARE THE DETAILS AND RESPONSES FOR YOUR QUERY.
          </p>
          <div className="query-item">
            <div className="query-dot-top-right"></div>
            <div className="query-header">
              <span className="query-title">{"You"}</span>
            </div>
            <div className="query-body">
              <p>
                {query.explanation}
              </p>
              <p className="query-user">From User: {query.username}</p>
              <div className="d-flex justify-content-end">
                <span className="query-date">{new Date(parseInt(query.date)).toLocaleDateString()}</span>
              </div>
              {query.images.filter(f => f != null).length > 0 ?
                <div className="d-flex flex-wrap">
                  {query.images.filter(f => f != null).map((value, index) => (
                    <div key={index} className="position-relative m-2" style={{ display: "inline-block" }}>
                      <img src={value} className="img-users-query-replies" />
                    </div>
                  ))}
                </div>
                : null}
            </div>
          </div>
          {replies.length > 0 && replies.map((value) => {
            return (
              <div className="query-item">
                <div className="query-dot-top-right"></div>
                <div className="query-header">
                  <span className="query-title">{value.isByUser ? "You" : "Givvy Customer Support"}</span>
                </div>
                <div className="query-body">
                  <p>
                    {value.explanation}
                  </p>
                  <div className="d-flex justify-content-end">
                    <span className="query-date">{new Date(parseInt(value.date)).toLocaleDateString()}</span>
                  </div>
                  {value.images.filter(f => f != null).length > 0 ?
                    <div className="d-flex flex-wrap">
                      {value.images.filter(f => f != null).map((value, index) => (
                        <div key={index} className="position-relative m-2" style={{ display: "inline-block" }}>
                          <img src={value} className="img-users-query-replies" />
                        </div>
                      ))}
                    </div>
                    : null}
                </div>

              </div>
            )
          })}

          <div className="query-item">
            <div className="query-dot-top-right"></div>
            <div className="query-header">
              <span className="query-title">Your Reply</span>
            </div>
            <div className="d-flex justify-content-start">
              <span className="query-subtitle">Lets us know the details of your case or send us a screenshot of it.</span>
            </div>
            <div className="query-body">
              <textarea onChange={(e) => { setExplanation(e.target.value) }} className="query-body-text-area" placeholder="Type your comments here..."></textarea>
            </div>
          </div>

          <div className="query-item file-upload">
            <div className="query-dot-bottom"></div>
            <div className="query-header">
              <span className="query-title">Upload Image</span>
            </div>
            <div className="d-flex justify-content-start">
              <span className="query-subtitle">Provide images of the exact scenario.</span>
            </div>
            <div className="query-body upload-area" onClick={handleClick} style={{ cursor: "pointer" }}>
              <div className="upload-icon">
                <img src={require("./assets/uploadIcon2.png")} alt="Upload Icon" />
              </div>
              <p className="upload-text">
                Drop your image or video <span className="highlight">here</span>
              </p>
              <p className="upload-support">
                Supports: <strong>PNG, JPG, JPEG, WEBP, MP4, MOV</strong>
              </p>

              {/* Input de archivo oculto */}
              <input
                onChange={handleImage}
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/png, image/jpeg, image/jpg, image/webp, video/mp4, video/mov"
                multiple
              />
            </div>
          </div>
          <div className="d-flex flex-wrap">
            {selectedImages && selectedImages.map((value, index) => (
              <div key={index} className="position-relative m-2" style={{ display: "inline-block" }}>
                {/* Imagen */}
                <img src={value} alt={`Uploaded ${index}`} style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "5px" }} />

                {/* Botón de eliminar */}
                <button
                  onClick={() => removeImage(index)}
                  className="img-users-query"
                >
                  X
                </button>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-center">
            <div className="button-container">

              <button onClick={() => { resolveIssue() }} className="custom-button resolved">RESOLVED</button>
              <button
                onClick={() => { sendReply() }}
                className={`custom-button submit ${isLoading ? 'bluur' : 'bluur2'}`}
                disabled={isLoading} // Optionally disable button while loading
              >
                {isLoading ? 'Loading...' : 'SUBMIT'}
              </button>          </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button onClick={() => { selectQuery(null) }} className="custom-button submit">GO BACK</button>
          </div>
        </div>

      </>
    );
  }
};

export default QueryDetails;
