// Import React and useState
import Axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const queryString = require('query-string');

const regex = /<link>(.*?)<\/link>/g;
// const data = require('./exampleJson.json')
// Main Chatbot component
const Chatbot = ({ hideChat }) => {
  // State to manage chatbot messages and user input
  const [messages, setMessages] = useState([]);
  const [usernameQuery, setUsernameQuery] = useState(null)
  const [appNameQuery, setAppNameQuery] = useState('')
  const [stepSelected, setStepSelected] = useState(0)
  const [selectClass, setSelectClass] = useState('default-app')
  const [step, setStep] = useState(0); // Tracks the current conversation step
  const [userInputDesc, setUserInputDesc] = useState(""); // Tracks user's text input
  const [userInputEmail, setUserInputEmail] = useState(""); // Tracks user's text input
  const [inputDisabled, setInputDisabled] = useState(true); // Tracks user's text input
  const [data, setData] = useState({}); // Tracks user's text input
  const [isLoading, setIsloading] = useState(true); // Tracks user's text input
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [appNames, setAppNames] = useState([])
  const [userBrowser, setUserBrowser] = useState("")
  const [topic, setTopic] = useState('')
  const [inputSelected, setInputSelected] = useState(true)
  const [chat, setChat] = useState(false)
  const [placeholder, setPlaceholder] = useState('Please describe your issue briefly:')

  const chatEndRef = useRef(null); // Ref for scrolling to the bottom
  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    // Dispara el clic en el input de archivo
    fileInputRef.current.click();
  };
  // Random greetings array
  const greetings = [
    "Hello! How can I assist you today?",
    "Hi there! What can I help you with?",
    "Greetings! How may I assist you?",
    "Hey! Need help with something?",
    "Hi! How can I support you today?"
  ];
  function trackEvent(action, category, label, value) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
  }
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (5 MB = 5 * 1024 * 1024 bytes)
      if (file.size > 10 * 1024 * 1024) {
        alert('The file is too large. Maximum allowed size is 10 MB.');
        return;
      }

      setErrorMessage(null); // Clear any previous error messages

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setMessages((prev) => [
          ...prev,
          { sender: "user", image: reader.result }
        ]);
        console.log(reader.result)
      };
      reader.readAsDataURL(file);


    }
  };
  const getAppNames = () => {
    console.log(`appNames`)
    Axios.get('https://givvy-general-payment.herokuapp.com/getAllAppsSchemaForAdminPanel').then(res => {
      setAppNames(res.data.appObjects1)
      // if (!isGettedApps) {
      // }
    })
  }
  const handleRemoveImage = () => {
    setSelectedImage(null);
  };
  // Display random greeting on component mount
  useEffect(() => {
    getAppNames()
    getDataFromServer()
    const initialGreeting = greetings[Math.floor(Math.random() * greetings.length)];
    const parsed = queryString.parse(window.location.search);
    const uuid = localStorage.getItem("uuid")
    if (uuid) {
      setUserBrowser(uuid)
    } else {
      const fpPromise = FingerprintJS.load();
      fpPromise.then(fp => {
        fp.get().then(result => {
          setUserBrowser(result.visitorId)
          console.log(result.visitorId); // This is the pseudo-unique browser identifier
          // getQueriesByUser({ id: result.visitorId })
        });
      });
    }
    if (parsed.appName) {
      setAppNameQuery(parsed.appName)
      setUsernameQuery(parsed.username)
      setMessages([{ sender: "bot", text: initialGreeting }]);
    } else {
      setMessages([{ sender: "bot", text: initialGreeting }, { sender: "bot", text: "Please select an app and enter your username:" }]);
    }
  }, isLoading);

  // Scroll to the bottom of the chat when messages change
  useEffect(() => {
    let currChat = document.getElementById('chat-cont')
    if (currChat) {
      currChat.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, chat]);

  const getDataFromServer = () => {
    Axios.post(`https://givvy-backend-site.herokuapp.com/getDataForChatBot`, {}).then((res) => {
      console.log(res)
      setData(res.data.result)
      setIsloading(false)
    })
  }
  const sendDataToServer = () => {
    console.log(`answer: ${stepSelected} -> response from user: ${userInputDesc}`)
    if (userInputDesc != '') {
      Axios.post('https://givvy-backend-site.herokuapp.com/saveContactFormUser', {
        images: [selectedImage],
        userId: userBrowser,
        appName: appNames.find(f => f.realName == appNameQuery).appName,
        appRealName: appNameQuery,
        topic: topic,
        username: usernameQuery,
        explanation: `answer: ${stepSelected} -> response from user: ${userInputDesc}`,
        email: userInputEmail
      })
      document.getElementById('input-custom-desc').value = ""
      setSelectedImage(null)
      setUserInputDesc('')
    } else {
      alert('You cannot send an empty query!')
    }
  }
  // Handle bot responses based on button click and step
  const handleResponse = (input, test) => {
    const stepIndex = parseInt(step) - 1;
    let botResponse = ""
    console.log(input, step)
    if (input == "yes") {
      console.log(input)
      botResponse = greetings[Math.floor(Math.random() * greetings.length)];
      setMessages((prev) => [
        { sender: "bot", text: botResponse },
      ]);
      setStep(0)
    } else if (input == "no") {
      botResponse = "Thank you for reaching out! Have a great day.";
      setMessages((prev) => [
        ...prev,
        { sender: "user", text: "No" },
        { sender: "bot", text: botResponse },
      ]);
      setStep(null)
    } else if (step === 0) {
      // Selección de un tema principal
      setStep(parseInt(input));
      const issue = data.mainIssuesWithResponses[input - 1];
      console.log(input)
      setTopic(issue.mainIssueName)
      if (issue.mainIssueName == "Other General") {
        handleDescIssue(`${issue.mainIssueName}`, "Describe your problem with our apps and our team will look into it as soon as possible!")
        // setUserInput("");
        setStep(101)
      } else {
        setMessages((prev) => [
          ...prev,
          { sender: "user", text: issue.mainIssueName },
          { sender: "bot", text: "Please choose an option:" },
        ]);
        setStep(parseInt(input));
      }

    } else if (step >= 1) {
      if (input != 'submit') {
        const issue = data.mainIssuesWithResponses[stepIndex];
        const selectedAnswer = issue.answers[parseInt(input) - 1];
        if (selectedAnswer.needToPopulate) {

          if (selectedAnswer.answer != "") {
            setMessages((prev) => [
              ...prev,
              { sender: "user", text: selectedAnswer.option },
              { sender: "bot", text: selectedAnswer.answer },
            ]);
            handleDescIssue(null, selectedAnswer.placeholder)
          } else {
            handleDescIssue(`${issue.mainIssueName} - ${selectedAnswer.option}`, selectedAnswer.placeholder)
          }
          setStep(101)
          return;
        } else if (step == 101) {
          handleEmail(userInputDesc)
        } else {
          setMessages((prev) => [
            ...prev,
            { sender: "user", text: selectedAnswer.option },
            { sender: "bot", text: selectedAnswer.answer },
          ]);
          if (selectedAnswer.needToPopulate) {
            handleEmail(`${issue.mainIssueName}`)
            // setUserInput("");
            setStep(102)
          } else {
            handleMoreHelp()
          }
          return
        }
      } else {
        if (step == 101) {
          handleEmail(test ? test : userInputDesc)
          setStep(102)
        } else if (step == 102) {
          handleThanks(userInputEmail)
          handleMoreHelp()
          return
        }
      }
    }
  };

  const handleMoreHelp = () => {
    setInputDisabled(true)
    setStep(null)
    setTimeout(() => {
      let botResponse = ""
      botResponse = "Do you have any other questions or issues? Please respond with Yes or No.";
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: botResponse },
      ]);
      setStep(103)
      return
    }, 1500)
  }
  const handleThanks = (text) => {
    setInputSelected(true)
    setInputDisabled(true)
    setMessages((prev) => [
      ...prev,
      { sender: "user", text: text }
    ]);
    sendDataToServer()
    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: `Thank you for your time! We will analyze your question and respond shortly.` },
      ]);
      console.log(userInputDesc, userInputEmail)
      return
    }, 500)
  }
  const handleEmail = (text) => {
    console.log(text)
    console.log(userInputDesc)
    setInputSelected(false)
    setInputDisabled(false)
    setMessages((prev) => [
      ...prev,
      { sender: "user", text: text }
    ]);
    document.getElementById('input-custom-desc').value = ""
    document.getElementById('input-custom-desc').placeholder = "Type your Email..."

    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: "Thank you! Could you please provide your email so we can send you a solution?" },
      ]);
      return
    }, 500)
  }
  const handleDescIssue = (text, currPlaceholder) => {
    if (currPlaceholder) {
      setStepSelected(currPlaceholder)
    } else {
      setStepSelected(placeholder)
    }
    setInputDisabled(false)

    if (text) {
      setMessages((prev) => [
        ...prev,
        { sender: "user", text: text }
      ]);
    }
    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: currPlaceholder ? currPlaceholder : placeholder }
      ]);
      return
    }, 500)
    setTimeout(() => { document.getElementById('input-custom-desc').placeholder = "Issue.." }, 100)
  }
  const renderAppNames = () => {
    return appNames.map((value) => {

      return (<option className='option-drop'>{value.realName}</option>)
    })
  }
  const renderAppNameGlobal = () => {

    return <>
      <div className="select-container">
        <div className="icon2">
          <img src={require("../NewContactForm/assets/Vector1.png")}>
          </img>
        </div>
        <select defaultValue={appNameQuery == '' ? 'Choose an app' : appNameQuery} onChange={(e) => {
          setAppNameQuery(e.target.value);
          setSelectClass('our-app-givvy')
        }} className={`custom-select-m custom-select ${selectClass}`}>
          <option className='option-drop' disabled>Choose an app</option>
          {renderAppNames()}
        </select>
        <img src={require("../NewContactForm/assets/Vector2.png")} className="submit-button2">
        </img>
      </div>
    </>
  }
  const renderOptions = (step) => {
    if (!data || !data.mainIssuesWithResponses) {
      console.error("Data or mainIssuesWithResponses is undefined");
      return <p>Loading...</p>; // Mensaje de carga
    }

    if (step === 0) {
      // Renderizar opciones principales (Withdraw Issues, Earning Limitations, etc.)
      return (
        <div style={{
          display: 'flex',
          flexWrap: 'wrap', // Permite que los botones se envuelvan en varias filas
          justifyContent: 'center', // Centra las opciones horizontalmente
          alignItems: 'center', // Centra las opciones verticalmente
          gap: '10px', // Espacio entre los botones
          marginTop: '20px',
          padding: '10px',
        }}>
          {data.mainIssuesWithResponses.map((issue, index) => (
            <button
              key={index}
              onClick={() => handleResponse(index + 1)}
              className="custom-butt"
              style={{
                backgroundColor: '#ffffff', // Fondo blanco
                color: '#4a148c', // Texto morado
                padding: '10px 20px', // Tamaño del botón
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',

              }}
              onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
              onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
            >
              {issue.mainIssueName}
            </button>
          ))}
          {/* <button
            key={0}
            onClick={() => handleResponse("yes")}
            className="custom-butt-back"
            style={{
              backgroundColor: '#ffffff', // Fondo blanco
              color: '#4a148c', // Texto morado
              padding: '10px 20px', // Tamaño del botón
              fontSize: '14px',
              cursor: 'pointer',
              transition: 'transform 0.2s ease-in-out',

            }}
            onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
            onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
          >
            Back
          </button> */}
        </div>
      );
    } else if (step >= 1) {
      // Renderizar sub-opciones basadas en la selección del usuario
      if (step === 101) {
        // Renderizar textarea para capturar el problema del usuario
        return (null);
      } else if (step === 103) {

        // Renderizar opciones Yes/No
        setTimeout(() => { { setChat(true) } }, 200)
        return (
          <div style={{
            display: 'flex',
            flexWrap: 'wrap', // Permite que los botones se envuelvan en varias filas
            justifyContent: 'center', // Centra las opciones horizontalmente
            alignItems: 'center', // Centra las opciones verticalmente
            gap: '10px', // Espacio entre los botones
            marginTop: '20px',
            padding: '10px',
          }}>
            <button
              key={1}
              onClick={() => handleResponse("yes")}
              className="custom-butt"
              style={{
                padding: '10px 20px', // Tamaño del botón
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
              onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
            >
              Yes
            </button>
            <button
              key={2}
              onClick={() => handleResponse("no")}
              className="custom-butt"
              style={{
                padding: '10px 20px', // Tamaño del botón
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
              onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
            >
              No
            </button>
          </div>
        );
      } else if (step === 104 || step == 102) {
        // Renderizar formulario para capturar el correo electrónico del usuario
        return (null);
      } else {
        const issue = data.mainIssuesWithResponses[step - 1];
        return (
          <div style={{
            display: 'flex',
            flexWrap: 'wrap', // Permite que los botones se envuelvan en varias filas
            justifyContent: 'center', // Centra las opciones horizontalmente
            alignItems: 'center', // Centra las opciones verticalmente
            gap: '10px', // Espacio entre los botones
            marginTop: '20px',
            padding: '10px',
          }}>
            {issue.answers.map((answer, index) => (
              <button
                key={index}
                onClick={() => handleResponse(index + 1)}
                className="custom-butt"
                style={{

                  padding: '10px 20px', // Tamaño del botón
                  fontSize: '14px',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
                onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
              >
                {answer.option}
              </button>
            ))}
            <button
              key={0}
              onClick={() => handleResponse("yes")}
              className="custom-butt-back"
              style={{
                backgroundColor: '#ffffff', // Fondo blanco
                color: '#4a148c', // Texto morado
                padding: '10px 20px', // Tamaño del botón
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',

              }}
              onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
              onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
            >
              Back
            </button>
          </div>
        );
      }

    }

    // Fallback en caso de error
    return null;
  };
  const CustomInput = () => {
    return (
      <div className="input-container">
        <div className="icon">
          {/* Aquí podrías colocar un SVG o ícono */}
          <div>
            {/* Imagen como botón */}
            {usernameQuery && appNameQuery ? <img
              htmlFor="file-upload"
              src={require("../NewContactForm/assets/uploadIcon.png")}
              alt="Upload"
              className="submit-button"
              onClick={handleFileUploadClick} // Dispara el clic del input
              style={{ cursor: 'pointer' }}
            /> : null}
            {/* Input oculto */}
            <input
              ref={fileInputRef} // Conectar la referencia
              type="file"
              id="file-upload"
              style={{ display: 'none' }} // Ocultar el input
              onChange={handleImageUpload} // Manejar el archivo seleccionado
            />
          </div>

        </div>
        <input
          id="input-custom"
          type="text"
          placeholder="Username..."
          className="custom-input"
        />
        <img
          onClick={() => {
            if (usernameQuery == null || usernameQuery == "") {
              let curr = document.getElementById('input-custom')
              if (curr.value == "" || curr.value == null) {
                alert("Not valid username")
              } else if (appNameQuery == '') {
                alert("App cannot be empty")
              } else {
                setUsernameQuery(curr.value)
                setMessages((prev) => [
                  ...prev,
                  { sender: "user", text: `App Name: ${appNameQuery}` },
                  { sender: "user", text: `Username: ${curr.value}` },
                  { sender: "bot", text: "Please choose an option:" }
                ]);
              }
            } else {
              if (step == 102) {
                if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userInputEmail)) {
                  handleResponse('submit')
                } else {
                  alert('Not valid email')
                }
              } else if (step == 101) {
                if (userInputDesc != "") {
                  handleResponse('submit')
                } else {
                  alert('Cannot be empty')
                }
              } else {
                handleResponse('submit')
              }
            }
          }} src={require("../NewContactForm/assets/buttonSend.png")} className="submit-button">
        </img>
      </div>
    );
  };
  const CustomInputDesc = () => {
    return (
      <div className="input-container-desc">
        <div className="icon">
          {/* Aquí podrías colocar un SVG o ícono */}
          <div>
            {/* Imagen como botón */}
            {usernameQuery && appNameQuery ? <img
              htmlFor="file-upload"
              src={require("../NewContactForm/assets/uploadIcon.png")}
              alt="Upload"
              className="submit-button"
              onClick={handleFileUploadClick} // Dispara el clic del input
              style={{ cursor: 'pointer' }}
            /> : null}

            {/* Input oculto */}
            <input
              ref={fileInputRef} // Conectar la referencia
              type="file"
              id="file-upload"
              style={{ display: 'none' }} // Ocultar el input
              onChange={handleImageUpload} // Manejar el archivo seleccionado
            />
          </div>

        </div>
        <input
          onChange={(e) => {
            if (step == 101) {
              setUserInputDesc(e.target.value)
            } else {
              setUserInputEmail(e.target.value)
            }
          }}
          id="input-custom-desc"
          type="text"
          placeholder="Username..."
          className="custom-input"
        />
        <img
          onClick={() => {
            if (userInputDesc == null || userInputDesc == "") {
              let curr = document.getElementById('input-custom-desc')
              if (curr.value == "" || curr.value == null) {
                if (usernameQuery == "") {
                  alert("Username cannot be empty")
                } else {
                  alert("Issue cannot be empty")
                }
              } else if (appNameQuery == '') {
                alert("App cannot be empty")
              } else {
                console.log(curr.value)
                setUserInputDesc(curr.value)
                setStep(101)
                handleResponse('submit')
              }
            } else {
              if (step == 102) {
                let curr = document.getElementById('input-custom-desc')
                if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(curr.value)) {
                  setUserInputEmail(curr.value)
                  handleResponse('submit')
                } else {
                  alert('Not valid email')
                }
              } else if (step == 101) {
                if (userInputDesc != "") {
                  handleResponse('submit')
                } else {
                  alert('Cannot be empty')
                }
              } else {
                handleResponse('submit')
              }
            }
          }} src={require("../NewContactForm/assets/buttonSend.png")} className="submit-button">
        </img>
      </div>
    );
  };

  const addHyperLink = (link) => {
    document.getElementById("output").innerHTML = `<link>${link}</link>`
  }

  if (!isLoading) {
    return (
      <div
        className='main-chat-container'
      >
        <button
          onClick={() => { hideChat() }}
          className="fixed-button-close"
        >
          ×
        </button>
        {/* Chat Container */}
        <div
          className='chat-custom-m'
          style={{
            flex: 1,
            width: '100%',
            maxWidth: '600px',
            overflowY: 'auto',
            padding: '15px',
            scrollbarWidth: 'none', // Ocultar scrollbar en Firefox
            position: "relative"

          }}
        >
          {messages.map((msg, index) => (
            <>
              <div
                key={index}
                style={{
                  margin: '15px 0',
                  display: 'flex',
                  flexDirection: msg.sender === 'bot' ? 'row' : 'row-reverse', // Aquí cambia según el emisor
                  alignItems: 'center', // Alinea verticalmente el contenido

                }}
              >
                {msg.sender === 'bot' && (
                  <img
                    src={require("../NewContactForm/assets/robotChat.png")}
                    alt="Bot Avatar"
                    style={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%',
                      marginRight: '10px',
                      // border: '2px solid #4CAF50',
                    }}
                  />
                )}
                {msg.sender === 'user' && (
                  <img
                    src={require("../NewContactForm/assets/profilechat.png")}
                    alt="User Avatar"
                    style={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%',
                      marginLeft: '10px',
                      border: "none",
                      marginRight: '0', // Asegura que no haya margen derecho
                    }}
                  />
                )}
                <div
                  className='text-msg'
                  style={{
                    backgroundColor: msg.sender === 'bot' ? '#4CAF50' : '#3F3878',
                    color: '#fff',
                    borderRadius: '20px',
                    padding: '12px 18px',
                    fontSize: '15px',
                    maxWidth: 'calc(100% - 80px)',
                    wordWrap: 'break-word',
                    textAlign: 'left', // Siempre alinea el texto a la izquierda
                  }}
                >
                  {msg.text && msg.text.includes("</a>") ? <div dangerouslySetInnerHTML={{ __html: msg.text }} /> : msg.text}
                  {msg.image && (
                    <>
                      {msg.image.match(/image/) ? (
                        <img
                          src={msg.image}
                          alt="Preview"
                          style={{
                            marginTop: '10px',
                            maxWidth: '100px',
                            maxHeight: '100px',
                            borderRadius: '10px',
                          }}
                        />
                      ) : msg.image.match(/video/) ? (
                        <video
                          controls
                          width="100"
                          height="100"
                          style={{ borderRadius: '10px', marginTop: '10px' }}
                        >
                          <source src={msg.image} type={`video/${msg.image.split('.').pop()}`} />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <p>Unsupported file type</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ))}
          {!appNameQuery || !usernameQuery ?
            <div className='inputs-custom-m'>
              {renderAppNameGlobal()}
              {CustomInput()}
            </div> :
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '10px',
                  marginTop: '20px',
                }}
              >
                {renderOptions(step)}
              </div>
              <div id={"chat-cont"} />
            </>
          }

          {!inputDisabled &&
            <div className='inputs-custom-m'>
              {CustomInputDesc()}
            </div>
          }
          <div className='separator'></div>
          {!appNameQuery || !usernameQuery ? <div className='autoAi'>Responses are generated by ChatGPT</div> : null}
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default Chatbot;
